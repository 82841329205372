<!--<app-home-main-section [data]="userService.data"></app-home-main-section>
<div class="carousel-cards">
  <ng-container *ngIf="!userIsActive || !userService.data?.permissions?.openServices;else elseCardSod">
    <app-card-default [data]="userService.data"></app-card-default>
  </ng-container>
  <ng-template #elseCardSod>
    <app-card-sod  [data]="userService.data"></app-card-sod>
  </ng-template>
</div>-->
<app-app-navigation-header
  [title]="'CRÉDITO DE NOMÍNA'"
  [background]="'#4e488f'"
  [color]="'#fff'"
></app-app-navigation-header>
<div class="main-f-card main-f-card-top main-f-card-medium">
  <ng-container *ngIf="userService.data && userService.data.permissions.openServices">
    <!--<app-home-current-flow [data]="userService.data"></app-home-current-flow>-->
    <app-home-actions [data]="userService.data"></app-home-actions>
  </ng-container>
</div>
