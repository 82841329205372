<div class="section-login">
  <div class="section-login_content">
    <img src="assets/media/images/logo/logo.png">
    <h3>Tu aliado financiero</h3>
    <p>Disfruta de todos los beneficios que PULPI tiene para ti</p>
  </div>
  <div class="section-login_buttons">
    <!--<button pButton pRipple label="Crear cuenta" class="p-button-primary button-block mb-3" [routerLink]="'/onboarding'"></button>-->
    <button pButton pRipple label="Inicia sesión" class="p-button-outlined button-block" [routerLink]="'/login'"></button>
  </div>
</div>
