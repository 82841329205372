<div class="main-f-card-zero">
<ng-container *ngIf="data">
  <div class="container-verifications px-4 mb-5">
    <ng-container *ngIf="data.stages.rejected.length > 0">
      <h5 class="my-3">Rechazados</h5>
      <p>Revisa el comentario del motivo de rechazo del documento y vuelve a completar el paso, </p>
      <div class="stages">
        <ng-container *ngFor="let stage of data.stages.rejected">
          <div class="stages-stage progress">
            <div class="icon">
              <fa-icon [icon]="faCircleQuestion"></fa-icon>
            </div>
            <div class="stages-stage_info">
              <h4>{{stage.properties.title}}</h4>
              <p>{{stage.properties.subtitle}}</p>
            </div>
            <div class="next">
              <fa-icon [icon]="faChevronRight" (click)="onClick.emit(stage)"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="data.stages.inValidation.length > 0">
      <h5 class="my-3">En proceso de aprobación</h5>
      <div class="stages">
        <ng-container *ngFor="let stage of data.stages.inValidation">
          <div class="stages-stage rejected">
            <div class="icon">
              <fa-icon [icon]="faCircleQuestion"></fa-icon>
            </div>
            <div class="stages-stage_info">
              <h4>{{stage.properties.title}}</h4>
              <p>{{stage.properties.subtitle}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="data.stages.unfinished.length > 0">
      <h5 class="my-3">En proceso</h5>
      <p class="text-sm mb-3">
        Asegúrate de completar cada etapa correctamente
      </p>
      <div class="stages">
        <ng-container *ngFor="let stage of data.stages.unfinished">
          <ng-container *ngIf="stage.disabled;else elseProcess">
            <div class="stages-stage {{stage.disabled ? 'disabled': 'available'}}">
              <div class="icon">
                <fa-icon [icon]="faCircle"></fa-icon>
              </div>
              <div class="stages-stage_info">
                <h4>{{stage.properties.title}}ss</h4>
                <p>{{stage.properties.subtitle}}</p>
              </div>
              <div class="next">
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #elseProcess>
            <div class="stages-stage {{stage.disabled ? 'disabled': 'available'}}"  (click)="onClick.emit(stage)">
              <div class="icon">
                <fa-icon [icon]="faCircle"></fa-icon>
              </div>
              <div class="stages-stage_info">
                <h4>{{stage.properties.title}}</h4>
                <p>{{stage.properties.subtitle}}</p>
              </div>
              <div class="next">
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="data.stages.inProcess.length > 0">
      <h5 class="my-3">Por verificar</h5>
      <p class="text-sm mb-3">
        Estamos verificando tu información, te notificaremos cuando el siguiente paso esté disponible.
      </p>
      <div class="stages">
        <ng-container *ngFor="let stage of data.stages.inProcess">
          <ng-container *ngIf="stage.disabled;else elseProcessInProcess">
            <div class="stages-stage {{stage.disabled ? 'disabled': 'available'}}">
              <div class="icon">
                <fa-icon [icon]="faCircle"></fa-icon>
              </div>
              <div class="stages-stage_info">
                <h4>{{stage.properties.title}}</h4>
                <p>{{stage.properties.subtitle}}</p>
              </div>
              <div class="next">
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #elseProcessInProcess>
            <div class="stages-stage {{stage.disabled ? 'disabled': 'available'}}" (click)="onClick.emit(stage)">
              <div class="icon">
                <fa-icon [icon]="faCircle"></fa-icon>
              </div>
              <div class="stages-stage_info">
                <h4>{{stage.properties.title}}</h4>
                <p>{{stage.properties.subtitle}}</p>
              </div>
              <div class="next">
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="data.stages.completed.length > 0">
      <h5 class="my-3">Verificado</h5>
      <div class="stages">
        <ng-container *ngFor="let stage of data.stages.completed">
          <div class="stages-stage completed">
            <div class="icon">
              <fa-icon [icon]="faCheckCircle"></fa-icon>
            </div>
            <div class="stages-stage_info">
              <h4>{{stage.properties.title}}</h4>
              <p>{{stage.properties.subtitle}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
</div>
