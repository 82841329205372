
  <app-app-navigation-header
    [title]="'MI BALANCE'"
    [background]="'#4e488f'"
    [color]="'#fff'"
  ></app-app-navigation-header>
  <div class="main-f-card main-f-card-top main-f-card-medium">
    <!--<app-balance-balances></app-balance-balances>-->
    <app-balance-movements></app-balance-movements>
  </div>
