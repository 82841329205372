<app-app-navigation-header
  [title]="'Beneficios'"
  [background]="'#4e488f'"
  [color]="'#fff'"
></app-app-navigation-header>
<div class="main-f-card main-f-card-top main-f-card-medium">
  <div class="list-benefits">
    <ng-container *ngFor="let benefit of benefits">
      <app-benefit-card [background]="benefit.background" [image]="benefit.image" [title]="benefit.title" [actionLabel]="benefit.actionLabel" [url]="benefit.url"></app-benefit-card>
    </ng-container>
  </div>
</div>
