<div class="main-f-card">
<ng-container *ngIf="entity">
  <ng-container *ngIf="max > 0; else elseComment;">
  <form [formGroup]="entity">
    <div class="grid pt-5 px-4">
      <div class="col-12">
        <p>Oferta pre-autorizada</p>
      </div>
      <div class="col-12 mt-2 comment-rejected" *ngIf="data.comment && data.comment !== ''">
        Motivo de rechazo: <strong>{{data.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <div class="col-12">
        <div class="field field-flex">
          <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX" [inputStyle]="{fontSize: '32px', fontWeight: 700, background: '#F6F7FC'}" (onKeyDown)="clearOffers()"></p-inputNumber>
          <small class="mt-1">Puedes solicitar desde <strong>{{min | currency:'MXN'}}</strong> hasta <strong>{{max | currency: 'MXN'}}</strong></small>
          <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min'] | currency: 'MXN'}}.</small>
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['max']">El valor máximo es {{entity.get('amount')?.errors?.['max']['max'] | currency:'MXN'}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h4 class="mb-4" *ngIf="offers.length > 0">Selecciona una oferta</h4>
        <div class="flex flex-column gap-3">
          <div *ngFor="let offer of offers" class="field-checkbox mb-1">
            <p-radioButton inputId="installmentCount" name="installmentCount" [value]="offer" formControlName="installmentCount"></p-radioButton>
            <label [for]="offer.key" class="ml-2">{{ offer.name }}</label>
          </div>
        </div>
      </div>
      <div class="col-12 button-block-container mb-1">
        <button pButton pRipple label="Calcular ofertas" icon="pi pi-check" class="p-button text-center justify-content-center button-block" (click)="calculateOffers()" [disabled]="!entity.get('amount')?.valid"></button>
      </div>
      <div class="col-12 button-block-container mb-3">
        <button pButton pRipple label="Aplicar para oferta" icon="pi pi-check" class="p-button-outlined text-center justify-content-center button-block" (click)="applyForOffer()" [disabled]="entity.invalid"></button>
      </div>
    </div>
  </form>
  </ng-container>
  <ng-template #elseComment>
    <div class="grid pt-5 px-4">
      <div class="col-12 mt-2 comment-rejected mb-4">
        Lo sentimos no podemos ofrecerte un crédito no cuentas con capacidad suficiente, puedes contactarte a soporte o intentarlo en 30 días.
      </div>
      <div class="col-12 button-block-container mb-3">
        <button pButton pRipple label="Lo entiendo" icon="pi pi-check" class="p-button-outlined text-center justify-content-center button-block" (click)="understand()"></button>
      </div>
    </div>
  </ng-template>
</ng-container>
</div>
