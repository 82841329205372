import {Component, OnInit} from '@angular/core';
import {AuthService} from "./data/services/modules/auth/auth.service";
import {LoaderService} from "./data/services/page/loader.service";
import {UserService} from "./data/services/page/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FinsusService} from "./data/services/page/finsus.service";
import {PrimeNGConfig} from "primeng/api";
import {PushingService} from "./data/services/api/flow/pushing.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulpi-v4-app';
  showLeftMenu = false

  constructor(
    public authService: AuthService,
    public loaderService: LoaderService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private finsusService: FinsusService,
    private config: PrimeNGConfig,
    private pushingService: PushingService
  ) {
    this.config.setTranslation({
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Acaba en',
      equals: 'Igual',
      notEquals: 'No igual',
      noFilter: 'Sin filtro',
      lt: 'Menor que',
      matchAll: 'Coincide con todos',
      matchAny: 'Coincide con cualquiera',
      dateIs: 'La fecha es',
      dateIsNot: 'La fecha no es',
      dateAfter: 'La fecha es despues de',
      dateBefore: 'La fecha es antes de',
      addRule: 'Agregar regla',
      clear: 'Limpiar',
      apply: 'Aplicar',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      lte: 'Menor o igual que',
      weak: 'Debil',
      medium: 'Aceptable',
      strong: 'Fuerte',
      passwordPrompt: 'Ingresa una contraseña'
    })
    this.activatedRoute.queryParamMap.subscribe((r:any) => {
      if (r.params && r.params.finsusAction) {
        console.log("mirame sienteme")
        this.loaderService.showSpinner()
        this.pushingService.hasPendingFlow({rfc: this.finsusService.getRFC(r.params.token)}).subscribe(rr => {
          console.log("pending flow", rr, rr.data.hasPendingProcess && this.authService.currentUser === null, this.authService.currentUser )
          this.finsusService.setParameters((rr.data.hasPendingProcess && this.authService.getUser === null && r.params.finsusAction === 'NEW_ACCOUNT') ? 'NEW_ACCOUNT_LOGOUT':r.params.finsusAction, r.params.token, r.params.data)
          //console.log("finsusAction", this.finsusService.action, this.finsusService.token)
          this.finsusService.runProcess()
          this.loaderService.hideSpinner()
        })


        //this.router.navigate(['/login'])
        // http://localhost:6100/?finsusAction=LOAN_DETAIL&token=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjY2ZmFmOTZlM2JjNWY2Njg4ZjMwZjRiMyJ9&data=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjEyMzQiLCJwaG9uZSI6IjU1NjY3Nzg4OTkiLCJmaXJzdE5hbWUiOiJKT1NFIiwic2Vjb25kTmFtZSI6IklWQU4iLCJmYXRoZXJMYXN0TmFtZSI6Ik1BUlRJTkVaIiwibW90aGVyTGFzdE5hbWUiOiJMQVJJT1MiLCJpbmNvZGVJZCI6IjY2NzI3NzI5ODM5Mjk4MyJ9
      }
    })
  }

  get getWhatsapp() {
    return  'https://wa.me/525580284233?text=Hola+necesito+ayuda'
  }

}
