<div class="container">
  <ng-container *ngIf="!showReceipt; else  elseReceipt">
    <app-app-navigation-header
      [title]="'Retiro de dinero'"
      [backRoute]="'/'"
    ></app-app-navigation-header>
    <div class="message mx-3">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
    </div>
    <form [formGroup]="entity">
      <div class="row p-4">
        <div class="col-12 mb-3">
          ¿Cuánto quieres retirar?
        </div>
        <div class="col-12">
          <div class="field field-flex">
            <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX" [inputStyle]="{fontSize: '32px', fontWeight: 700, background: '#F6F7FC'}"></p-inputNumber>
            <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
              <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min'] | currency: 'MXN'}}.</small>
              <small class="p-error" *ngIf="entity.get('amount')?.errors?.['max']">El valor máximo es {{entity.get('amount')?.errors?.['max']['max'] | currency:'MXN'}}.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field field-flex">
            <label for="accountId">Cuenta CLABE:</label>
            <p-dropdown id="accountId" formControlName="accountId" [panelStyle]="{'width': '100%' }" [options]="accounts" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
            <ng-container *ngIf="entity.get('accountId')?.invalid && (entity.get('accountId')?.dirty || entity.get('accountId')?.touched)">
              <small class="p-error" *ngIf="entity.get('accountId')?.errors?.['required']">Debes seleccionar una cuenta.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 mb-5">
          <p class="mb-2">Monto disponible: <strong>{{userService.data?.sodProperties?.availableAppSalary | currency:'MXN'}}</strong></p>
          <p class="mb-2">Monto máximo por retiro: <strong>{{userService.data?.sodProperties?.amountByWithdrawal | currency:'MXN'}}</strong> </p>
          <p class="mb-2">Comisión:
            <strong>
            <ng-container *ngIf="userService.data?.sodProperties?.withdrawalFeeIsPercent;else currencyFee">
              {{entity.value.amount * (userService.data?.sodProperties?.realFee ?? 1) * 0.01 | currency: 'MXN'}}
            </ng-container>
            <ng-template #currencyFee>
                {{((userService.data?.sodProperties?.fee ?? 0) | currency: 'MXN')}}
            </ng-template>
            </strong>
          </p>
          <p>
            Total a pagar:
            <strong>
              <ng-container *ngIf="userService.data?.sodProperties?.withdrawalFeeIsPercent;else currencyTotalFee">
                {{+entity.value.amount + (entity.value.amount * (userService.data?.sodProperties?.realFee ?? 1) * 0.01) | currency: 'MXN'}}
              </ng-container>
              <ng-template #currencyTotalFee>
                {{(entity.value.amount + (userService.data?.sodProperties?.fee ?? 0)) | currency: 'MXN'}}
              </ng-template>
            </strong>
          </p>
        </div>
        <div class="col-12">
          <div class="col-12 flex flex-column">
            <button pButton pRipple label="Confirmar" class="p-button-primary button-block mb-3" (click)="confirm()" [disabled]="entity.invalid || isLoading"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-template #elseReceipt>
    <app-withdrawal-receipt [withdrawal]="withdrawalReceipt"></app-withdrawal-receipt>
  </ng-template>
  <p-confirmDialog></p-confirmDialog>
</div>

