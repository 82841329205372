<app-app-navigation-header
  title="Inicio de sesión"
></app-app-navigation-header>
<div class="main-f-card main-f-card-top main-f-card-medium">
  <form [formGroup]="entity">
    <div class="login-form">
      <img src="assets/media/images/logo/logo.png">
      <h3>Inicia sesión</h3>
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="false" [escape]="false"></p-messages>
      </div>
      <div class="field field-flex">
        <label for="emailAddress">Email:</label>
        <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="user@pulpi.mx" required />
        <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
          <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
        </ng-container>
      </div>
      <div class="field field-flex">
        <label for="password">Contraseña:</label>
        <p-password [feedback]="false" placeholder="************" formControlName="password" [toggleMask]="true"></p-password>
        <ng-container *ngIf="entity.get('password')?.invalid && (entity.get('password')?.dirty || entity.get('password')?.touched)">
          <small class="p-error" *ngIf="entity.get('password')?.errors?.['required']">El campo es obligatorio.</small>
        </ng-container>
        <div class="restore-password">
          <a [routerLink]="'/reset-password'">Olvidé mi contraseña</a>
        </div>
      </div>
    </div>
    <div class="login-button button-block-container">
      <button pButton pRipple label="Continuar" class="p-button-primary button-block mb-3" (click)="login()" [disabled]="entity.invalid"></button>
    </div>
  </form>
</div>
