import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../data/services/modules/auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authService.getUser;
    if (currentUser) {
      const queryParams = state.root.children[0].queryParams
      this.router.navigate(['/balance'], {queryParams});
      return false;
    }
    // not logged in, so redirect to login page with the return url
    return true;
  }
}
