<div class="container-screen">
  <app-app-navigation-header
    [title]="'Detalle del retiro'"
    [background]="'#4e488f'"
    [color]="'#fff'"
    [backRoute]="'/balance'"
  ></app-app-navigation-header>
  <div class="main-f-card main-f-card-top main-f-card-medium">
  <div class="row p-4">
    <ng-container *ngIf="movement; else elseMovement">
    <div class="col-12">
      <div class="data-values">
        <p>Folio</p>
        <p-tag [value]="movement.folio" [severity]="'primary'"></p-tag>
      </div>
      <div class="data-values">
        <p>Fecha del retiro</p>
        <p>{{movement.date | date: 'd-MM-YYYY HH:mm:ss'}}</p>
      </div>
      <div class="data-values">
        <p>Fecha para liquidar</p>
        <p>{{movement.dueDate | date: 'dd-MM-YYYY'}}</p>
      </div>
      <div class="data-values">
        <p>Monto del retiro</p>
        <p>{{movement.amount | currency:'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Comisión del retiro</p>
        <p>{{movement.fee}}</p>
      </div>
      <div class="data-values">
        <p>Total del retiro</p>
        <p>{{movement.fee + movement.amount | currency:'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Monto pagado</p>
        <p>{{movement.collectedAmount | currency:'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Pendiente de pagar</p>
        <p>{{(movement.collectedAmount - movement.amount - movement.fee < 0) ? 0 : (movement.collectedAmount - movement.amount - movement.fee)}}</p>
      </div>
      <div class="data-values">
        <p>Estatus de dispersión</p>
        <p-tag [value]="movement.status | selectEnum: $_movementEnum" [severity]="movement.status | selectEnum: $_movementEnumColor"></p-tag>
      </div>
      <div class="data-values">
        <p>Estatus de pagado</p>
        <p-tag [value]="(movement.collectedAmount >= (movement.amount + movement.fee - 0.1) ? 'PAID' : 'IN_PROCESS') | selectEnum: $_movementEnum" [severity]="(movement.collectedAmount >= (movement.amount + movement.fee - 0.1) ? 'PAID' : 'IN_PROCESS') | selectEnum: $_movementEnumColor"></p-tag>
      </div>
    </div>
    </ng-container>
    <ng-template #elseMovement>
      <div class="col-12 text-center">
        No se pudo obtener la información
      </div>
    </ng-template>
  </div>
  </div>
</div>
