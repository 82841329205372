import {Component, ViewChild} from '@angular/core';
import {WorkerService} from "../../../../data/services/api/worker.service";
import {Message} from "primeng/api";
import {LoaderService} from "../../../../data/services/page/loader.service";
import {IOnboarding} from "../../../../data/interfaces/ionboarding.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../../data/services/modules/auth/auth.service";
import {Router} from "@angular/router";
import {FinsusService} from "../../../../data/services/page/finsus.service";

@Component({
  selector: 'app-onboarding-process',
  templateUrl: './onboarding-process.component.html',
  styleUrls: ['./onboarding-process.component.scss']
})
export class OnboardingProcessComponent {
  responseMessage: Message[] = []
  showMessage = false
  stage = 'CREDENTIALS'
  backRoute = '/'
  mainPoints: {label: string; value: string}[] = []
  data!: IOnboarding
  entityGeneralInformationData: FormGroup
  entityChooseSectorData: FormGroup
  entityChooseProductData: FormGroup
  entityCreateCredentialsData: FormGroup
  constructor(
    private fb: FormBuilder,
    private workerService: WorkerService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private router: Router,
    private finsusService: FinsusService
  ) {
    this.entityGeneralInformationData = this.fb.group({
      firstName: [this.finsusService.data?.firstName ?? '', [Validators.required, Validators.minLength(3)]],
      lastName: [this.finsusService.data?.fatherLastName ?? '', [Validators.required, Validators.minLength(3)]],
      curp: [this.finsusService.data?.curp, [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/)]],
      civilStatus: ['SINGLE', [Validators.required, Validators.minLength(3)]],
      nationality: ['MEXICAN', [Validators.required]],
      advisor: [''],
      employeeNumber: ['']
    })
    this.entityChooseSectorData = this.fb.group({
      sector: ['', [Validators.required]],
      dependency: ['', [Validators.required]]
    })

    this.entityChooseProductData = this.fb.group({
      desireProduct: ['PULPI_LOAN', [Validators.required]]
    })

    this.entityCreateCredentialsData = this.fb.group({
      rfc: [this.finsusService.data?.rfc ?? '', [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][0-9A-Z]{3}$/)]],
      phoneNumber: ['+52'+(this.finsusService.data?.phone ?? ''), [Validators.required, Validators.pattern(/^\+52[0-9]{10}$/)]],
      emailAddress: [this.finsusService.data?.email ?? '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]*[a-zA-Z][a-zA-Z0-9_.+-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      password: ['', [Validators.required, Validators.minLength(3)]]
    })
  }

  sendData(step: string) {
    console.log(step)
    switch (step) {
      case 'GENERAL_INFORMATION':
        this.sendRequest({data: this.entityGeneralInformationData.value, stage: 'VALIDATE_GENERAL_INFORMATION'})
        break;
      case 'CHOOSE_SECTOR':
        this.backRoute = '/onboarding'
        this.stage = 'CHOOSE_PRODUCT'
        break;
      case 'CHOOSE_PRODUCT':
        this.backRoute = '/onboarding'
        this.stage = 'CREDENTIALS'
        break;
      case 'CREDENTIALS':
        this.sendRequest({data: {
            ...this.entityGeneralInformationData.value,
            ...this.entityChooseSectorData.value,
            ...this.entityChooseProductData.value,
            ...this.entityCreateCredentialsData.value
          }, stage: 'CREATE_ACCOUNT'})
        break;
    }

  }

  sendRequest ( data: any) {
    this.loaderService.showSpinner()
    this.responseMessage = []
    this.workerService.createAccount(data).subscribe(r => {
      console.log(r)
      this.loaderService.hideSpinner()
      if (r.error) {
        this.responseMessage = [
          {
            summary: r.msg,
            severity: 'error',
            closable: false,
          }
        ]
      } else {
        if(r.data.nextStage === 'LOGIN') this.autoLogin(this.entityCreateCredentialsData.value)
        else  this.stage = r.data.nextStage
        if (r.data.products) {
          this.mainPoints = r.data.products.map(p => {
            return {
              label: p.name,
              value: p.id
            }
          })
        }
        this.data = r.data
        this.backRoute = '/verify-documents'

      }
    })
  }

  autoLogin (data: any) {
    this.authService.login(data).subscribe(r => {
      if (r.error) {
        this.showMessage = true
        this.responseMessage = [
          {
            summary: r.msg,
            severity: 'error',
            closable: true
          }
        ]
      } else if (!r.error) {
        this.authService.updateUserLocalData(r.data)
        this.router.navigate(['/verify-documents'])
      }
    })
  }
}
