import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faArrowLeft, faChevronLeft, faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-app-navigation-header',
  templateUrl: './app-navigation-header.component.html',
  styleUrls: ['./app-navigation-header.component.scss']
})
export class AppNavigationHeaderComponent {
  icons = {
    back: faChevronLeft,
    close: faTimes
  }
  @Input() title = ''
  @Input() background = '#23223F'
  @Input() color = '#fff'
  @Input() backRoute = ''
  @Input() closeRoute = ''
  @Input() rightIcon = faTimes
  @Output() onClick = new EventEmitter()
}
