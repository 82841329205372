import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {IWorkerProperties} from "../../../modules/private/home/interfaces/worker.interface";
import {WorkerService} from "../api/worker.service";
import {AuthService} from "../modules/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "./loader.service";
import {FinsusService} from "./finsus.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  subjectUser: BehaviorSubject<IWorkerProperties | null>
  currentUser: Observable<IWorkerProperties | null>
  constructor(
    private workerService: WorkerService,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private finsusService: FinsusService
  ) {
    this.subjectUser = new BehaviorSubject<IWorkerProperties | null>(null)
    this.currentUser = this.subjectUser.asObservable()
    if (this.authService.getUser) this.getData()
    this.activatedRoute.queryParamMap.subscribe((r:any) => {
      if (r.params && r.params.finsusAction) {
        this.finsusService.setParameters(r.params.finsusAction, r.params.token, r.params.data)
        this.finsusService.runProcess()
      }
    })
  }

  goHome() {
    this.router.navigate(['/'])
  }
  getData() {
    if(this.data === null) this.loaderService.showSpinner()
    this.workerService.getUserProperties().subscribe(r => {
      console.log(r.data)
      this.loaderService.hideSpinner()
      if (r.error) {
        console.log('here')
        this.authService.logout()
      } else this.set(r.data)
    })
  }

  set(user: IWorkerProperties) {
    this.subjectUser.next(user)
  }

  get data(): IWorkerProperties | null {
    return this.subjectUser.value
  }
}
