<div class="container-screen">
  <app-app-navigation-header
    [title]="'Agregar cuenta'"
    [backRoute]="'/accounts'"
    [closeRoute]="'/accounts'"
  ></app-app-navigation-header>
  <div class="main-f-card main-f-card-top main-f-card-medium">
    <div class="message mx-3">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
    </div>
    <form [formGroup]="entity">
      <div class="row p-4">
        <div class="col-12">
          <div class="field field-flex">
            <label for="account">Cuenta CLABE:*</label>
            <input type="text" pInputText id="account" formControlName="account" placeholder="424242424242424242"  required />
            <ng-container *ngIf="entity.get('account')?.invalid && (entity.get('account')?.dirty || entity.get('account')?.touched)">
              <small class="p-error" *ngIf="entity.get('account')?.errors?.['required']">El campo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('account')?.errors?.['pattern']">La cuenta clabe debe tener 18 digitos.</small>
            </ng-container>
          </div>
        </div>
        <div class="field field-flex mb-5">
          <label for="owner">Titular de la cuenta</label>
          <input type="text" pInputText id="owner" formControlName="owner" placeholder="John Doe" required />
          <ng-container *ngIf="entity.get('owner')?.invalid && (entity.get('owner')?.dirty || entity.get('owner')?.touched)">
            <small class="p-error" *ngIf="entity.get('owner')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
        <div class="col-12 button-block-container px-0">
          <button pButton pRipple label="Crear cuenta" class="p-button button-block" (click)="createAccount()" [disabled]="entity.invalid || isLoading"></button>
        </div>
      </div>
    </form>
  </div>
</div>
