<div class="main-f-card">
<ng-container *ngIf="settings;">
  <div class="grid px-4">
    <div class="col-12 my-4">
      <p>
        {{settings.description}}
      </p>
    </div>
    <div class="col-12 mt-2 comment-rejected" *ngIf="data.comment && data.comment !== ''">
      Motivo de rechazo: <strong>{{data.comment}}</strong>
    </div>
    <div class="col-12">
      <div class="img img-start" style="background-image: url('{{settings.img}}')"></div>
    </div>
    <div class="col-12 button-block-container">
      <button  pButton pRipple label="Entiendo" icon="fa-solid fa-check p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="this.onEndProcess.emit(true)"></button>
    </div>
  </div>
</ng-container>
</div>
