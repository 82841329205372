<app-app-navigation-header
  [backRoute]="backRoute"
  title="Información general"></app-app-navigation-header>

<div class="main-f-card main-f-card-top main-f-card-medium">
<ng-container [ngSwitch]="stage">
  <div class="message mx-3">
    <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
  </div>
  <ng-container *ngSwitchCase="'GENERAL_INFORMATION'">
    <app-onboarding-general-information (onSendData)="sendData('GENERAL_INFORMATION')" [entity]="entityGeneralInformationData"></app-onboarding-general-information>
  </ng-container>
    <ng-container *ngSwitchCase="'CHOOSE_PRODUCT'">
      <app-onboarding-chose-product [data]="data" [entity]="entityChooseProductData" [mainPoints]="mainPoints" (onSendData)="sendData('CHOOSE_PRODUCT')"></app-onboarding-chose-product>
    </ng-container>
  <ng-container *ngSwitchCase="'CREDENTIALS'" >
    <app-onboarding-create-credentials [entity]="entityCreateCredentialsData" (onSendData)="sendData('CREDENTIALS')" #credentialsForm></app-onboarding-create-credentials>
  </ng-container>
</ng-container>
</div>
