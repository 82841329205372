<app-app-navigation-header
  [title]="'MIS CUENTAS'"
  [background]="'#4e488f'"
  [color]="'#fff'"
></app-app-navigation-header>
<div class="main-f-card main-f-card-top main-f-card-medium">
<div class="message mx-3">
  <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
</div>
<div class="container-accounts mb-8">
  <ng-container *ngFor="let account of accounts">
    <app-accounts-account (onClick)="confirm(account.id)" [owner]="account.owner" [id]="account.id" [account]="account.account"></app-accounts-account>
  </ng-container>
  <button pButton pRipple label="Agregar cuenta" class="p-button-outlined button-block" [routerLink]="'create'"></button>
</div>
</div>
<p-confirmDialog></p-confirmDialog>
