import {Component, OnInit} from '@angular/core';
import {AuthService} from "./data/services/modules/auth/auth.service";
import {LoaderService} from "./data/services/page/loader.service";
import {UserService} from "./data/services/page/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FinsusService} from "./data/services/page/finsus.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulpi-v4-app';
  showLeftMenu = false

  constructor(
    public authService: AuthService,
    public loaderService: LoaderService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private finsusService: FinsusService
  ) {
    console.log(this.activatedRoute.queryParams)
    this.activatedRoute.queryParamMap.subscribe((r:any) => {

      if (r.params && r.params.finsusAction) {
        this.finsusService.setParameters(r.params.finsusAction, r.params.token, r.params.data)
        console.log("finsusAction", this.finsusService.action, this.finsusService.token, this.finsusService.data)
        this.finsusService.runProcess()

        //this.router.navigate(['/login'])
        // http://localhost:6100/?finsusAction=LOAN_DETAIL&token=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjY2ZmFmOTZlM2JjNWY2Njg4ZjMwZjRiMyJ9&data=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjEyMzQiLCJwaG9uZSI6IjU1NjY3Nzg4OTkiLCJmaXJzdE5hbWUiOiJKT1NFIiwic2Vjb25kTmFtZSI6IklWQU4iLCJmYXRoZXJMYXN0TmFtZSI6Ik1BUlRJTkVaIiwibW90aGVyTGFzdE5hbWUiOiJMQVJJT1MiLCJpbmNvZGVJZCI6IjY2NzI3NzI5ODM5Mjk4MyJ9
      }
    })
  }

  get getWhatsapp() {
    return this.userService.data?.user.endDatePosition !== null ?  'https://wa.me/527299441002?text=Hola+necesito+ayuda' : 'https://wa.me/525580284233?text=Hola+necesito+ayuda'
  }

}
